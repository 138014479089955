import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import I18n, { localize, i18n } from 'locale/I18n'
import MediaGrid from 'components/grid/MediaGrid'
import WebSection from 'components/web/WebSection'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import medialookup from 'redux/modules/medialookup'
import Loading from 'components/misc/Loading'
import Path from 'util/PathUtil'
import AddThis from 'api/AddThis'

class DownloadView extends PureComponent {

    componentDidMount() {
        let { lookupCode } = this.props;
        this.props.actions.lookupMedia(lookupCode);
        // LazyLoad AddThis on DownloadView Page.
        AddThis.init();
    }
    
    renderMediaDetailActions = item => {
        let url = `https:${item.mediaUrl}`, // prepend scheme so urls work with social sharing
            title = i18n('media.share.default_quote'),
            hashtag = i18n('media.share.hashtag'),
            downloadUrl = Path.getDownloadUrl(item.mediaUrl);
        
        return (
            <div className="purchase-actions">
                {/* 
                  This downloads the image directly from S3. We need S3 to set the proper HTTP
                  headers to cause the browser to download directly, and that's not happening
                  right now. As a result, it opens the media in a new window.

                  The necessary CORS headers to proxy the file locally aren't present.

                  UPDATE: This is now handled by the server. The downloadUrl is a signed URL
                */}
                <a className="purchased-btn" href={downloadUrl}>
                    <i className="icon download"></i>
                </a>
                <div className="purchased-btn">
                    <FacebookShareButton url={url} quote={title} hashtag={`#${hashtag}`}>
                        <FacebookIcon size={40} />
                    </FacebookShareButton>
                </div>
                <div className="purchased-btn">
                    <TwitterShareButton url={url} title={title} hashtags={[hashtag]}>
                        <TwitterIcon size={40} />
                    </TwitterShareButton>
                </div>
            </div>
        )
    }
    
    render() {
        let { media } = this.props;
        
        return media
            ?
            <WebSection className="download-view web-view">
                {
                    media.length
                        ?
                        <>
                            <div className="view-head">
                                <I18n $="download.heading" />
                                <a href="https://www.iflyworld.com" target="blank"><I18n $="download.www_link" /></a>
                            </div>
                            <MediaGrid media={media} mediaDetailActions={this.renderMediaDetailActions} />
                        </>
                        :
                        <div className="view-head no-media"><I18n $="download.no_media" /></div>
                }
            </WebSection>
            :
            <Loading/>
    }
    
    static propTypes = {
        // reach-router
        lookupCode: PropTypes.string.isRequired,
        
        // redux -- medialookup.connect()
        isLoading: PropTypes.bool.isRequired,

        media: PropTypes.arrayOf(MediaPropTypes.selectableMedia),
        
        actions: PropTypes.shape({
            lookupMedia: PropTypes.func.isRequired
        }).isRequired
    }
}

export default localize(medialookup.connect(DownloadView));